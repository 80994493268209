/* make variables */
:root {
  --lightgray: #D9D9D9;
  --gray:white;
  --orange:rgb(255, 255, 60);
  --darkGrey: black;
  --caloryCard: black;
  --planCard: yellow;
  --appColor:  rgb(0, 0, 0);
}

.App {
  
  mix-blend-mode: overlay;
  background-color: var(--appColor);
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}
::-webkit-scrollbar{
  display: none;
}

.blur {
  background: rgb(255, 255, 60);
  position: absolute;
  border-radius: 50%;
  filter: blur(212px);
  z-index: -9;
  filter: blur(150px);
}
.btn {
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
}
.btn:hover {
  cursor: pointer;
}

.stroke-text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
