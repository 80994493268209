@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

.footer {
  background: linear-gradient(180deg, #000000 60%, #d1ff2b 400%, #000000 0%);
  color: #fff;
  padding: 2rem 1rem; /* Adjust padding to fit content within viewport */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
}

.footer-bottom {
  text-align: center;
  padding: 1rem 0;
  width: 100%;
}

.footer-column {
  flex: 1;
  min-width: 250px;
  margin: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.footer-logo img {
  max-width: 150px;
}

.contact-info, .address, .social-links, .opening-hours {
  margin: 10px 0;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.social-icons a {
  color: #fff;
  font-size: 24px;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #ff4500;
}

h3 {
  margin-bottom: 10px;
  font-size: 18px;
}

table {
  width: 100%;
  border-collapse: collapse; /* Ensures table borders collapse into a single border */
}

table, th, td {
  border-top: 1px solid #fff; /* White border around table cells */
  border-bottom: 1px solid #fff; /* White border around table cells */
  padding: 8px;
  text-align: left;
}

th {
  background-color: #000000; /* Dark background color for table header */
  color: #fff;
}

td {
  background-color: #000000; /* Dark background color for table cells */
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin: 10px 0;
    text-align: center;
  }
}
