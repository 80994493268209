@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

.header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Align header items to the center */
    padding: 1rem -5rem; /* Adjust padding as needed */
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.logo {
    width: 150px;
    height: auto;
}

.header-menu {
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;
    margin: 0;
    padding: 0 5rem;
    transition: transform 0.3s ease-in-out;
}

.header-menu > li {
    position: relative;
    padding: 1rem 0;
}

.header-menu > li::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--orange);
    transition: width 0.3s;
}

.header-menu > li:hover::after {
    width: 100%;
}

.header-menu > li:hover {
    color: var(--orange);
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .header {
        justify-content: space-between;
        padding: 2rem -3rem;
    }
    .logo {
        width: 120px;
        height: auto;
        margin-top: 1rem;;
        margin-left: 1rem;;
    }

    .header-menu {
        flex-direction: column;
        background-color:rgba(0, 0, 0, 0.5); /* Semi-transparent background */
        padding: 2rem;
        position: fixed;
        top: 0;
        right: 0;
        width: 20%;
        height: 50%;
        transform: translateX(100%);
        gap: 1rem;
    }

    .header-menu.open {
        transform: translateX(0);
    }

    .header-menu > li {
        padding: 1rem 0;
    }

    .bars-icon {
       
        padding: 0.5rem;
        border-radius: 5px;
        cursor: pointer;
    }

    .bars-img {
        width: 1.5rem;
        height: 1.5rem;
        padding: 0 0.4rem;
    }
}
