
.testimonials{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 6rem;
    padding-bottom: 4rem;
}
.left-t{
    display: flex;
    flex: 1.1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;
    color: white;
}
.outer{
    display: flex;
    flex: 1;
    gap: 2rem;
    flex-direction: row;
}

.left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}

.left-t>:nth-child(2){
    font-weight: bold;
    font-size: 3rem;
}
.a{
    width:80%;
    margin-right: 2rem;
 
 }

.textx{
    text-transform: none;
    text-align: start;
    letter-spacing: 2px;
    line-height: 40px;
    font-size: 1.5rem;
}
.right-t{
    flex:1;
    position: relative;
}
.right-t>img{
    position: relative;
    width: 17rem;
    height: 20rem;
    object-fit: cover;

}
.right-t>div{
    position:relative;
    display:none;
}
.right-t>:last-child{
    display: block;
    bottom: 0;
    left: 0;
}
.right-t>:nth-child(1)
{
    position: absolute;
    width: 17rem;height: 19rem;
    right: 7rem;
    top: 4rem;
    border: 2px solid orange;
    background-color: transparent;
   
}
.right-t>:nth-child(2)
{
    position: absolute;
    border: 2px solid orange;
    background-color: rgb(0, 0, 0);
    right: 9rem;
    top: 0.9rem;
    width: 17rem;height: 20rem;
}
.arrows{
    display: flex;
    gap: 1rem;
    position: absolute;
    bottom: 1rem;
    left: 3rem;
    padding-left: 40%;
    
}
.arrows>img{
    width: 1.5rem;
    cursor: pointer
}

@media screen and (max-width: 767px) {
    .testimonials{
        flex-direction: row;
        padding: 1rem 0rem;
        margin-left: 2rem;
    }
    .outer{
        gap:1rem;
        max-width: 100%;
        margin-left: 0rem;
    }

    .a{
       width:80%;
       margin-right: 2rem;
    
    }
    .a> ul{
        align-self: left;
    }
    .left-t {
        width: 100%;
        
    }
    .left-t>:nth-child(1){
        padding-right: 2rem;
    }
    .left-t>:nth-child(2){
        font-size: 2rem;
    }

    .right-t{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-top: 1rem;
    }

    .right-t>div{
        position:relative;
        display:none;
    }

    .right-t>img{
        top: 0;
        right: 0;
        position:relative;
        align-self: right;
        width: 100px;
        height: auto;
        padding-right: 3rem;
    }

    .right-t>.arrows{
       
        padding-right: 3rem;

    }
    .right-t>:last-child{
        display: block;
        bottom: 0;
        left: 0;
    }

    .left-t>:nth-child(3 ){
        text-transform: none;
        text-align: start;
        letter-spacing: 2px;
        line-height: 30px;
        font-size: 0.6rem;
    }
    .textx{
        text-transform: none;
        text-align: start;
        letter-spacing: 2px;
        line-height: 20px;
        font-size: 0.8rem;
    }
    .arrows{
        display: flex;
        gap: 1rem;
        position: absolute;
        bottom: 1rem;
        left: 3rem;
        padding-left: 0;
        
    }
    .arrows>img{
        width: 1rem;
        cursor: pointer
    }
}