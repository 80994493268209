.Programs {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 7rem 2rem;
}

.programs-header {
  display: flex;
  gap: 20rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  font-style: italic;
}

.program-categories {
  display: flex;
  gap: 1rem;
}

.category {
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 2rem;
  gap: 1rem;
  color: white;
  flex: 1;
  justify-content: space-between;
  transition: background-color 0.5s ease, color 0.5s ease; /* Smooth transition */
  position: relative; /* Added for absolute positioning of hover-bar */
  height: 15rem;
}

.category-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.category > :nth-child(1) {
  width: 2rem;
  height: 2rem;
  fill: white;
}

.category > :nth-child(2) {
  font-size: 1.3rem;
  font-weight: bold;
}

.category > :nth-child(3) {
  font-size: 0.9rem;
  line-height: 25px;
}

.join-now {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.join-now > img {
  width: 1rem;
}

.hover-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 4px;
  background-color: var(--orange);
  transition: width 0.3s ease-in-out;
}

.category:hover .hover-bar {
  width: 100%;
}


@media screen and (max-width: 767px) {

  .programs-header {
    flex-direction: column;
    gap: 1rem;
    font-size: x-large;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
  }

  .program-categories {
    flex-direction: column;
  }
}
